// Hubspot
import React, { useEffect, useState } from 'react';
import Script from 'next/script';
import { useAuth } from '../../../hooks/useAuth';
import { hubspotIdentify } from '../../../api/brand';
import { AUTH_STATUS } from '../../../types/auth';

export interface HubspotProps {}

function Hubspot({}: HubspotProps) {
  const {
    authState: { status },
  } = useAuth();
  const [identification, setIdentification] = useState<{
    identificationEmail: string;
    identificationToken: string;
  } | null>(null);

  useEffect(() => {
    const controller = new AbortController();

    if (status === AUTH_STATUS.AUTHENTICATED) {
      hubspotIdentify(controller.signal)
        .then((data) => {
          if (
            typeof data.identificationEmail === 'string' &&
            typeof data.identificationToken === 'string'
          ) {
            setIdentification({
              identificationEmail: data.identificationEmail,
              identificationToken: data.identificationToken,
            });
          } else {
            throw new Error('Invalid HubSpot identification data');
          }
        })
        .catch((e) => {
          console.error('Problem with identifying HubSpot user', e);
          setIdentification(null);
        });
    }

    return () => {
      controller.abort();
    }
  }, [status]);

  useEffect(() => {
    if (!identification) return;

    window.hsConversationsSettings = {
      loadImmediately: false,
      identificationEmail: identification.identificationEmail,
      identificationToken: identification.identificationToken,
    };

    /**
     */
    function loadOrRefreshWidget() {
      const status = window.HubSpotConversations?.widget?.status() ?? {
        loaded: false,
      };

      if (status.loaded) {
        window.HubSpotConversations?.widget?.refresh();
      } else {
        window.HubSpotConversations?.widget?.load();
      }
    }

    if (window.HubSpotConversations) {
      loadOrRefreshWidget();
    } else {
      window.hsConversationsOnReady = [loadOrRefreshWidget];
    }

    return () => {
      window.HubSpotConversations?.widget?.remove();
    };
  }, [identification]);

  return (
    <>
      <Script id="hubspot-chat-initial-settings">{`window.hsConversationsSettings = { loadImmediately: false };`}</Script>
      <Script
        id="hubspot-chat-sdk"
        src={`https://js-na1.hs-scripts.com/${process.env.NEXT_PUBLIC_HUBSPOT_TRACKING_CODE}.js`}
      />
    </>
  );
}

export default Hubspot;
