import React, { ErrorInfo } from "react";
import Error from "next/error";

interface ErrorProps {
  error: Error;
  info: ErrorInfo;
  clearError: () => void;
}

const ErrorView: React.FC<ErrorProps> = () => {
  return <Error statusCode={500} />;
};

export default ErrorView;
