import React, {
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useRouter } from 'next/router';
import {
  AuthHandlerResponse,
  getProfile,
  postAuthSignup,
  postGoogleLogin,
  postLoginUser,
  postLogout,
} from 'api/auth';
import {
  API_RESPONSE_STATUS,
  AuthState,
  AUTH_STATUS,
  BrandAuthResponse,
} from 'types/auth';
import Bugsnag, { addErrorMetaData } from 'services/bugsnag';
import { signInWithGooglePopup } from 'services/firebaseAuth';
import {
  clearBrowserAuth,
  setAuthToken,
  setToken,
  tokenExists,
} from 'utils/auth';
import { AUTH_TOKEN } from 'constants/auth';
import { setUserIdentity } from 'utils/analytics';
import { Brand } from 'types/brand';
import { filterUndefinedValues } from 'utils/object';
import { SoonaSignUpRequest, postSoonaSignup } from 'api/soona';

interface AuthenticationContext {
  authState: AuthState;
  logout: () => Promise<void>;
  login: (email: string, password: string) => Promise<AuthHandlerResponse>;
  googleLogin: () => Promise<AuthHandlerResponse>;
  signup: (email: string, password: string) => Promise<AuthHandlerResponse>;
  soonaSignup: (
    signUpRequest: SoonaSignUpRequest
  ) => Promise<AuthHandlerResponse>;
  refreshProfile: () => Promise<void>;
  updateProfile: (brandUpdates: Partial<Brand>) => void;
}

export const AuthContext = createContext<AuthenticationContext>(
  {} as AuthenticationContext
);

export const useAuth = (): AuthenticationContext => {
  return useContext(AuthContext);
};
// TODO: Improve implementation with useSWR for profile
export const AuthProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const router = useRouter();
  const [authState, setAuthState] = useState<AuthState>({
    status: AUTH_STATUS.LOADING,
  });

  useEffect(() => {
    if (!router.isReady) return;
    if (tokenExists(AUTH_TOKEN.REFRESH)) {
      if (authState.status !== AUTH_STATUS.AUTHENTICATED) {
        refreshProfile();
      }
    } else {
      setLoadingStatus(AUTH_STATUS.UNAUTHENTICATED);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.isReady]);

  const refreshProfile = async () => {
    try {
      getProfile().then(handleAuthAPIResponse);
    } catch (error) {
      logout();
    }
  };

  const setLoadingStatus = (status?: AUTH_STATUS.UNAUTHENTICATED) => {
    setAuthState((prev) => ({
      ...prev,
      status: status || AUTH_STATUS.LOADING,
    }));
  };

  const logout = async (clearLocalOnly = false) => {
    try {
      if (!clearLocalOnly && authState.status === AUTH_STATUS.AUTHENTICATED) {
        await postLogout();
      }
    } catch (error: any) {
      Bugsnag.notify('postLogout error', addErrorMetaData('error', { error }));
    }
    setAuthState({ status: AUTH_STATUS.UNAUTHENTICATED });
    clearBrowserAuth();
  };

  const googleLogin = async (): Promise<any> => {
    setLoadingStatus();
    return signInWithGooglePopup()
      .then(({ credential, error }) => {
        if (credential) {
          const { idToken, refreshToken } = credential;
          setAuthToken(idToken, refreshToken);
          return postGoogleLogin().then((res) => {
            return handleAuthAPIResponse(res, 'google');
          });
        }
        return handleAuthAPIResponse(error!);
      })
      .catch((err) => {
        console.log('catch');
        console.error(err);
      });
  };

  const login = async (
    email: string,
    password: string
  ): Promise<AuthHandlerResponse> => {
    setLoadingStatus();
    return postLoginUser(email, password).then(handleAuthAPIResponse);
  };

  const signup = async (
    email: string,
    password: string
  ): Promise<AuthHandlerResponse> => {
    setLoadingStatus();

    return postAuthSignup(email, password).then(handleAuthAPIResponse);
  };

  const soonaSignup = async (
    signUpRequest: SoonaSignUpRequest
  ): Promise<AuthHandlerResponse> => {
    setLoadingStatus();
    return postSoonaSignup(signUpRequest).then(handleAuthAPIResponse);
  };

  const handleAuthAPIResponse = (
    authResponse: AuthHandlerResponse,
    oauth = ''
  ) => {
    const { status, message } = authResponse;
    if (status === API_RESPONSE_STATUS.ERROR) {
      console.log('Error: ', message);
      setAuthState((auth) => ({
        ...auth,
        status: AUTH_STATUS.ERROR,
        error: message,
      }));
      // Logout
      clearBrowserAuth();
    } else {
      const brandAuth = authResponse.brandAuthResponse!;
      if (oauth === '') {
        const { idToken, refreshToken } = brandAuth as BrandAuthResponse;
        setAuthToken(idToken, refreshToken);
      }
      setToken(AUTH_TOKEN.CUSTOM, brandAuth.customToken);
      setAuthState({
        status: AUTH_STATUS.AUTHENTICATED,
        email: brandAuth.email,
        uid: brandAuth.localId,
        brand: brandAuth.brand,
      });
      setUserIdentity(brandAuth);
    }
    return { status, message };
  };

  const updateProfile = (brandUpdates: Partial<Brand>) => {
    const { brand } = authState;
    const updatedBrand = { ...brand, ...filterUndefinedValues(brandUpdates) };
    setAuthState((prevState: any) => ({ ...prevState, brand: updatedBrand }));
  };

  return (
    <AuthContext.Provider
      value={{
        authState,
        logout,
        login,
        googleLogin,
        signup,
        soonaSignup,
        refreshProfile,
        updateProfile,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
