// https://github.com/bugsnag/bugsnag-js/tree/next/examples/js/nextjs
import React from "react";
import Bugsnag, { OnErrorCallback } from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";
// import getConfig from "next/config";
// const { serverRuntimeConfig, publicRuntimeConfig } = getConfig();

const getReleaseStage = () => {
  if (process.env.STORYBOOK) {
    return "storybook";
  }
  return process.env.NEXT_PUBLIC_VERCEL_ENV || process.env.NODE_ENV;
};

Bugsnag.start({
  apiKey: process.env.NEXT_PUBLIC_BUGSNAG_API_KEY!,
  plugins: [new BugsnagPluginReact(React)],
  appVersion: require("../../package.json").version,
  releaseStage: getReleaseStage(),
  enabledReleaseStages: ["production", "preview", "development"],
});

export default Bugsnag;

const addErrorMetaData =
  (key: string, metadata: Record<string, any>): OnErrorCallback =>
  (event) => {
    event.addMetadata(key, metadata);
  };

export { addErrorMetaData };
