import { setProfileProperty, identify } from 'services/analytics/mixpanel';
import Bugsnag from 'services/bugsnag';
import { BrandAuthResponse, BrandOAuthResponse } from 'types/auth';

/**
 * MixPanel Tracking
 */
//
export const setUserIdentity = (
  authResponse: BrandAuthResponse | BrandOAuthResponse
) => {
  try {
    const { localId: userId, email } = authResponse;
    const { firstName, lastName } = authResponse.brand;
    const name = `${firstName || ''} ${lastName || ''}`;
    identify(userId);
    setProfileProperty('name', name);
    setProfileProperty('email', email);
    Bugsnag.setUser(userId, email, name);
  } catch (error) {
    console.error('Failed to set user identity');
    console.error(error);
  }
};

export const removeNullValues = (record: Record<string, any>) =>
  Object.fromEntries(
    Object.entries(record).filter(([, value]) => value != null)
  );
