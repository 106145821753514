import dayjs from 'dayjs';

export const calculateDateDifference = (
  startDate: number,
  endDate: number,
  unit: 'min' | 'day'
): number => {
  let timeConversion = 60 * 1000;
  if (unit === 'day') {
    timeConversion = timeConversion * 60 * 24;
  }
  return Math.round(Math.abs(endDate - startDate) / timeConversion);
};

// https://stackoverflow.com/a/19700358/9954196
export const msToTime = (
  /** Duration in seconds */
  duration: number
): string => {
  if (duration < 0) {
    return '00:00:00';
  }
  let hours: number | string = Math.floor(duration / (60 * 60));
  let minutes: number | string = Math.floor((duration / 60) % 60);
  let seconds: number | string = Math.floor(duration % 60);

  hours = hours < 10 ? '0' + hours : hours;
  minutes = minutes < 10 ? '0' + minutes : minutes;
  seconds = seconds < 10 ? '0' + seconds : seconds;

  return `${hours}:${minutes}:${seconds}`; // + '.' + milliseconds`;
};

export const getCountdown = (futureDate: number): string => {
  const currentTime = dayjs().unix();
  const approvalDiff = futureDate - currentTime;
  const timeToAutoApproval = msToTime(approvalDiff);
  return timeToAutoApproval;
};
