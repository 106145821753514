import Bugsnag from '@bugsnag/js';
import { trendFetch } from 'api';
import { MIXPANEL_EVENT } from 'constants/mixpanel';
import { trackEvent } from 'services/analytics/mixpanel';
import { addErrorMetaData } from 'services/bugsnag';
import { API_RESPONSE_STATUS } from 'types/auth';
import { standardizeErrorMessage } from 'utils/auth';
import { AuthHandlerResponse } from './auth';

export interface SoonaSignUpRequest {
  soonaJWT: string;
  email: string;
  password: string;
}

export const postSoonaSignup = async ({
  soonaJWT,
  password,
  email,
}: SoonaSignUpRequest): Promise<AuthHandlerResponse> => {
  return trendFetch('/auth/soona/link-brand', {
    method: 'POST',
    body: JSON.stringify({ soonaJWT, password, email }),
  })
    .then(async (res) => {
      const jsonResponse = await res.json();
      const { idToken, email } = jsonResponse;
      if (res.ok && idToken) {
        // NOTE: Any other analytics updates
        trackEvent(MIXPANEL_EVENT.BRAND_SIGNUP, { email, type: 'soona' });
        return {
          status: API_RESPONSE_STATUS.OK,
          message: 'success',
          brandAuthResponse: jsonResponse,
        };
      } else {
        if (res.status === 409) {
          return {
            status: API_RESPONSE_STATUS.ERROR,
            message: 'Email already registered, please login',
          };
        }
        const { message } = jsonResponse;
        const errorMessage = standardizeErrorMessage(
          message || 'Failed to sign up'
        );
        return { status: API_RESPONSE_STATUS.ERROR, message: errorMessage };
      }
    })
    .catch((err) => {
      Bugsnag.notify(
        'postSoonaSignup Error',
        addErrorMetaData('error', { error: err })
      );
      return { status: API_RESPONSE_STATUS.ERROR, message: 'Failed To Signup' };
    });
};

export const patchConnectAccount = async (soonaJWT: string) => {
  return trendFetch('/auth/soona/link-brand', {
    method: 'PATCH',
    body: JSON.stringify({ soonaJWT }),
  });
};
