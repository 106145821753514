import { Brand } from 'types/brand';
import { removeNullValues } from 'utils/analytics';

/**
 * Stape/Gtag Tracking
 */
declare global {
  interface Window {
    dataLayer: any;
  }
}

export enum GTagEvent {
  PURCHASE = 'Purchase',
  LAUNCH_CAMPAIGN = 'LaunchCampaign',
  FB_IMAGE_CAMPAIGN = 'PhotographyCampaign',
  FB_VIDEO_CAMPAIGN = 'VideoCampaign',
  INITIATE_CHECKOUT = 'InitiateCheckout',
  COMPLETE_REGISTRATION = 'complete_registration',
}
interface GTagTrackingEvent {
  event: GTagEvent;
  [key: string]: string | string[] | number | boolean | undefined;
}
export const trackGTagEvent = (data: GTagTrackingEvent) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push(data);
};

export const getBrandTrackingPayload = (brand: Brand) => {
  const brandFirstName = brand.fullName
    ? brand.fullName.split(' ').slice(0, -1).join(' ')
    : '';
  const firstName = brand.firstName || brandFirstName;

  const brandLastName = brand.fullName
    ? brand.fullName.split(' ').slice(-1).join(' ')
    : '';
  const lastName = brand.lastName || brandLastName;

  const brandPayload = {
    firstName,
    lastName,
    email: brand.email || brand.authEmail || null,
    country: brand.country || null,
    state: brand.state || null,
    city: brand.city || null,
    ipAddress: brand.ipAddress || null,
  };
  return removeNullValues(brandPayload);
};
