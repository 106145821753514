import { FIREBASE_CONFIG } from 'constants/firebaseConfig';
import { initializeApp } from 'firebase/app';
import { GoogleAuthProvider, getAuth, signInWithPopup, sendPasswordResetEmail } from 'firebase/auth';
import {
  API_RESPONSE_STATUS,
  APIHandlerResponse,
  FirebaseAuthSignUpResponse,
} from 'types/auth';

// TODO: Replace with ENV or Config files with production
// See: https://firebase.google.com/docs/web/learn-more#config-object

// Initialize Firebase
const config =
  process.env.NODE_ENV === 'production' &&
  process.env.NEXT_PUBLIC_IS_PREVIEW === 'false'
    ? FIREBASE_CONFIG.production
    : FIREBASE_CONFIG.development;
const app: any = initializeApp(config);

const auth = getAuth(app);
const provider = new GoogleAuthProvider();

export const signInWithGooglePopup = (): Promise<{
  credential?: FirebaseAuthSignUpResponse;
  error?: APIHandlerResponse;
}> =>
  signInWithPopup(auth, provider)
    .then(async (userCredential) => {
      const user = userCredential.user.toJSON() as any;
      const { uid, email } = user;
      const { accessToken, expirationTime, refreshToken } =
        user.stsTokenManager;

      return {
        credential: {
          idToken: accessToken,
          refreshToken,
          expiresIn: expirationTime,
          email,
          localId: uid,
        },
      };
    })
    .catch((error) => {
      // Handle Errors here.
      const errorCode = error.code;
      const errorMessage = error.message;
      console.error(`${errorCode}: ${errorMessage}`);
      return {
        error: {
          status: API_RESPONSE_STATUS.ERROR,
          message: errorMessage,
        },
      };
    });

export const passwordResetEmail = ( email: string ) => {
    const emailSend = sendPasswordResetEmail( auth, email )
        .then(() => `Password reset email sent to : ${ email }` )
        .catch((err: any) => console.log("[sendPasswordResetEmail]Error Message: ", err))
    
    return emailSend
};

