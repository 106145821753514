import { NextSeoProps } from "next-seo";

const DEFAULT_SEO: NextSeoProps = {
  titleTemplate: "%s | Trend",
  defaultTitle: "Trend Brands",
  description:
    "Trend connects brands with content creators in order to scale user-generated content across a variety of platforms while increasing their marketing ROI.",
  openGraph: {
    type: "website",
    locale: "en_IE",
    url: "https://app.trend.io/",
    site_name: "Trend Brands",
    description:
      "Trend connects brands with content creators in order to scale user-generated content across a variety of platforms while increasing their marketing ROI.",
  },
  twitter: {
    handle: "@trend_io",
    site: "@trend_io",
    cardType: "summary_large_image",
  },
};

export { DEFAULT_SEO };
