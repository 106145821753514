import { trendFetch } from './index';

export async function hubspotIdentify(signal?: AbortSignal) {
  const response = await trendFetch('/brand/hubspot/identification', {
    method: 'POST',
    signal,
  });

  return await response.json();
}
