import { Brand } from './brand';

export enum API_RESPONSE_STATUS {
  OK = 'ok',
  ERROR = 'error',
}

export type APIHandlerResponse = {
  status: API_RESPONSE_STATUS;
  message: string;
};

export interface FirebaseAuthSignUpResponse {
  idToken: string;
  email: string;
  refreshToken: string;
  expiresIn: string; // Seconds until idToken expires
  localId: string; // Firebase UID
}
export interface BrandAuthResponse extends FirebaseAuthSignUpResponse {
  brand: Brand;
  customToken: string;
}

export type BrandOAuthResponse = Omit<
  BrandAuthResponse,
  'idToken' | 'refreshToken' | 'expiresIn'
>;

export interface AuthLoginRequest {
  email: string;
  password: string;
}

export enum AUTH_STATUS {
  AUTHENTICATED = 'authenticated',
  UNAUTHENTICATED = 'unauthenticated',
  LOADING = 'loading',
  ERROR = 'error',
}

export type AuthState =
  | {
      status: AUTH_STATUS.UNAUTHENTICATED | AUTH_STATUS.LOADING;
      error?: string;
      email?: string;
      uid?: string;
      brand?: Brand;
    }
  | {
      status: AUTH_STATUS.ERROR;
      error: string;
      email?: string;
      uid?: string;
      brand?: Brand;
    }
  | {
      status: AUTH_STATUS.AUTHENTICATED;
      error?: undefined;
      email: string;
      uid: string;
      brand: Brand;
    };

export type AuthFormState = Record<'email' | 'password', string>;

export const AUTH_FORM_TYPE = {
  LOGIN: 'login',
  SIGN_UP: 'sign-up',
  FORGOT_PASSWORD: 'forgot-password',
} as const;

export type AuthFormType = (typeof AUTH_FORM_TYPE)[keyof typeof AUTH_FORM_TYPE];
export const isAuthFormType = (type: string): type is AuthFormType =>
  Object.values(AUTH_FORM_TYPE).includes(type as AuthFormType);

export const LINK_AUTH_FORM_TYPE = {
  ...AUTH_FORM_TYPE,
  CONNECT: 'connect',
  HAVE_AN_ACCOUNT: 'have_an_account',
} as const;
export type LinkAuthFormType =
  (typeof LINK_AUTH_FORM_TYPE)[keyof typeof LINK_AUTH_FORM_TYPE];
