export enum MIXPANEL_EVENT {
  BRAND_VISITS_PAGE = 'Brand Visits Page',
  // Auth
  BRAND_SIGNUP = 'Brand Signs Up',
  BRAND_LOGIN = 'Brand Logs In',
  BRAND_GOOGLE_LOGIN = 'Brand Logs In with Google',
  BRAND_LOGOUT = 'Brand Logs Out',
  // Uppy
  BRAND_UPLOADS_CONTENT = 'Brand Uploads Content',
  // Campaign
  BRAND_STARTS_CAMPAIGN_FORM = 'Brand Starts Campaign Form',
  BRAND_COMPLETES_CAMPAIGN_STEP = 'Brand Completes Campaign Step',
  BRAND_CLICKS_CAMPAIGN_BACK_BUTTON = 'Brand Clicks Campaign Back Button',
  BRAND_LAUNCHES_CAMPAIGN = 'Brand Launches Campaign',
  BRAND_START_EDIT_CAMPAIGN = 'Brand Starts Edit Campaign',
  BRAND_COMPLETES_EDIT_CAMPAIGN = 'Brand Completes Edit Campaign',
  // Purchase
  BRAND_STARTS_CHECKOUT = 'Brand Starts Checkout',
  BRAND_PURCHASE_CONFIRMATION = 'Brand Purchase Confirmation',
  // Content
  BRAND_APPROVES_SELECTED_CONTENT = 'Brand Approves Selected Content',
  BRAND_DOWNLOADS_CONTENT = 'Brand Downloads Content',
  BRAND_VIEWS_CONTENT = 'Brand Views Content',
  BRAND_CLICKS_MESSAGE_BUTTON = 'Brand Clicks Message Button',
  // Other
  BRAND_VIEWS_ADD_CREDITS_MODAL = 'Brand Views Add Credits Modal',
  BRAND_CLICKS_ADD_CREDITS_BUTTON = 'Brand Clicks Add Credits Button',
}
