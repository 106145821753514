export const FIREBASE_CONFIG = {
  // TOOD: Do we need new config here?
  production: {
    apiKey: 'AIzaSyDBtzY4WCSonGdMOvy-GEoNjXtICsDuDPI',
    authDomain: 'app.trend.io',
    databaseURL: 'https://verbv2.firebaseio.com',
    projectId: 'verbv2',
    storageBucket: 'verbv2.appspot.com',
    messagingSenderId: '480689852664',
    appId: '1:480689852664:web:b488a7bf7d29bdac39eb0d',
    measurementId: 'G-YDNE8ESXE5',
  },
  development: {
    apiKey: 'AIzaSyDGQMahcz2yEqegZ181T9tc1JuJnuOOVLs',
    authDomain: 'staging.trend.io',
    databaseURL: 'https://development-verbv2.firebaseio.com',
    projectId: 'development-verbv2',
    storageBucket: 'development-verbv2.appspot.com',
    messagingSenderId: '861962464857',
    appId: '1:861962464857:web:bb8fec26b0c4f047',
  },
};
