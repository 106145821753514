import 'styles/globals.sass';
import React from 'react';
import Bugsnag from 'services/bugsnag';
import Error from 'components/views/Error';
import { DefaultSeo } from 'next-seo';
import { DEFAULT_SEO } from 'config/seo.config';
import Script from 'next/script';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { trackPage } from 'services/analytics/mixpanel';
import { AuthProvider } from 'hooks/useAuth';
import { AppPropsWithLayout } from 'types/page';
import RootWrapper from 'components/containers/RootWrapper';
import Hubspot from 'components/common/Hubspot';

const ErrorBoundary = Bugsnag.getPlugin('react')?.createErrorBoundary() as any;
const renderWithErrorBoundary = (children: JSX.Element) => {
  return ErrorBoundary ? (
    <ErrorBoundary FallbackComponent={Error as any}>{children}</ErrorBoundary>
  ) : (
    children
  );
};

const App = ({ Component, pageProps }: AppPropsWithLayout) => {
  const router = useRouter();
  useEffect(() => {
    const handleRouteChange = (url: string, _shallow: boolean) => {
      trackPage(url);
    };
    router.events.on('routeChangeComplete', handleRouteChange);
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events]);

  const getLayout = Component.getLayout ?? ((page) => page);

  return renderWithErrorBoundary(
      <AuthProvider>
        <DefaultSeo {...DEFAULT_SEO} />
        <Script
          src={`https://maps.googleapis.com/maps/api/js?key=${process.env.NEXT_PUBLIC_GOOGLE_API_KEY}&libraries=places`}
        />
        <RootWrapper>{getLayout(<Component {...pageProps} />)}</RootWrapper>
        <Hubspot />
      </AuthProvider>
  );
};

export default App;
