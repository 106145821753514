import { ContentExampleProps } from 'components/views/Auth/ContentExamples';
import { AuthFormType } from 'types/auth';

export enum AUTH_TOKEN {
  ACCESS = 'access_token',
  REFRESH = 'refresh_token',
  CUSTOM = 'trend_custom_auth',
}

export const TOKEN_EXPIRATIONS: Record<AUTH_TOKEN, number> = {
  [AUTH_TOKEN.ACCESS]: 60 * 5, // 5 Minutes
  [AUTH_TOKEN.REFRESH]: 60 * 60 * 24 * 7, // 1 Week
  [AUTH_TOKEN.CUSTOM]: 60 * 5,
};

// UI Assets
export const EXAMPLE_ASSETS: Array<ContentExampleProps> = [
  {
    imgSrc: '/images/example-content/olipop.jpeg',
    contentType: 'Product Photography',
    company: 'Olipop',
  },
  {
    imgSrc: '/images/example-content/no-surrender-gear.jpeg',
    contentType: 'Lifestyle Photography',
    company: 'No Surrender Gear',
  },
  {
    imgSrc: '/images/example-content/kara-md.jpeg',
    contentType: 'Product Photography',
    company: 'KaraMD',
  },
  {
    imgSrc: '/images/example-content/kraywoods.jpeg',
    contentType: 'Portrait Photography',
    company: 'Kraywoods',
  },
  {
    imgSrc: '/images/example-content/vigorem.jpeg',
    contentType: 'Product Photography',
    company: 'Vigorem',
  },
];

export type AuthFormCopy = Record<
  'title' | 'subtitle' | 'actionButtonText' | 'toggleText' | 'toggleButtonText',
  string
>;
export const AUTH_FORM_COPY: Record<AuthFormType, AuthFormCopy> = {
  login: {
    title: 'Welcome Back,',
    subtitle: 'Pair up with creators across the country',
    actionButtonText: 'LOGIN',
    toggleText: "Don't have an account?",
    toggleButtonText: 'Sign Up',
  },
  'sign-up': {
    title: 'Start your free content brief',
    subtitle: 'Create an account below',
    actionButtonText: 'SIGN UP',
    toggleText: 'Already have an account?',
    toggleButtonText: 'Login',
  },
  'forgot-password': {
    title: 'Enter your email below to reset your password. Check your inbox.',
    subtitle: '',
    actionButtonText: 'RESET PASSWORD',
    toggleText: '',
    toggleButtonText: '',
  },
};
