export const getKeysWithUpdatedValues = <
  U extends Record<string, any>,
  P extends Record<string, any>
>(
  objUpdated: U,
  objPrev: P
) =>
  Object.keys(objUpdated).filter(
    (key: string) => (objUpdated as any)[key] !== objPrev![key as keyof P]
  ) as Array<keyof U>;

export const filterObjByKey = <T extends Record<string, any>>(
  record: T,
  keys: string[]
): Partial<T> =>
  Object.keys(record)
    .filter((k) => keys.includes(k))
    .reduce((obj, key: keyof T) => {
      obj[key] = record[key];
      return obj;
    }, {} as Partial<T>);

export const filterUndefinedValues = <T extends Record<string, any>>(
  record: T
): Partial<T> =>
  Object.keys(record)
    .filter((k) => typeof record[k] !== 'undefined' && record[k] !== null)
    .reduce((obj, key: keyof T) => {
      obj[key] = record[key];
      return obj;
    }, {} as Partial<T>);
